<script>
import GraphIcon from "@/assets/images/misc/graph_icon.svg";
import LoginApprovalsIcon from "@/assets/images/customers/login-approvals.svg";
import CustomerMergeIcon from "@/assets/images/customers/customer-merge.svg";
import LogsIcon from "@/assets/images/customers/people-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ConfigIcon from "@/assets/images/memberships/cog_icon.svg";

export default {
  name: "CustomerTopBar",
  components: {ConfigIcon, SvgIcon, LogsIcon, GraphIcon,LoginApprovalsIcon,CustomerMergeIcon},
  props: {
    global: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    tabs(){
      return [
        {
          name: "Logs",
          icon: LogsIcon,
          path: ["/customers"],
          permission: this.checkWritePermission(this.$modules.clients.customers.slug)
        },
        {
          name: "Graph",
          icon: GraphIcon,
          path: ["/customers/graphs"],
          permission: this.checkWritePermission(this.$modules.clients.graph.slug)
        },
        {
          name: "Merge Customer",
          icon: CustomerMergeIcon,
          path: ["/customers/merging"],
          permission: this.checkWritePermission(this.$modules.clients.customer_merge.slug)
        },
        {
          name: "Login Approvals",
          icon: LoginApprovalsIcon,
          path: ["/customers/login-approvals"],
          permission: this.checkWritePermission(this.$modules.clients.restrict_app_login.slug)
        },
        {
          name: "Configuration",
          icon: ConfigIcon,
          path: ["/customers/configuration"],
          permission: this.checkWritePermission(this.$modules.clients.customers.slug)
        },
      ]
    }
  },
  methods:{
    gotoPage(path) {
      this.$router.push(path);
    },
  }
}
</script>

<template>
  <div v-if="global" class="d-flex p-4 rounded bg-white bordered w-fit tabs">
    <template v-for="(tab) in tabs">
      <div
          v-if="tab.permission"
          v-bind:key="tab.name"
          class="nv_item d-flex pointer tab"
          @click="gotoPage(tab.path[0])"
      >
        <SvgIcon
            :class="{
                    'qp-tab-nav-is-active':tab.path.includes($route.path),
                    'qp-tab-nav':!tab.path.includes($route.path)
                  }"
            :text="tab.name"
            class="text-thin-gray"
        >
          <template v-slot:icon>
            <component :is="tab.icon" :component="tab.icon"/>
          </template>
        </SvgIcon>
      </div>
    </template>
  </div>

</template>

<style scoped>

</style>